import createOverlay from '../../../_helpers/overlay';
import { openLocationSwitcher } from './location-switcher';

const bodyCssClass = 'menu-active';

export default () => {
    const menu = document.querySelector('[data-menu]');

    if (!menu) {
        return;
    }

    const overlay = createOverlay(() => document.body.classList.remove(bodyCssClass));
    const triggers = [...document.querySelectorAll('[data-menu-trigger]')];

    // Power up the triggers
    triggers.forEach(el => {
        el.addEventListener('click', e => {
            e.preventDefault();

            if (document.body.classList.contains(bodyCssClass)) {
                overlay.hide();
                triggers.forEach(el => el.ariaExpanded = 'false');
            } else {
                overlay.show();
                triggers.forEach(el => el.ariaExpanded = 'true');
                document.body.classList.add(bodyCssClass);
            }
        });
    });

    // Power up the submenu togglers
    document.querySelectorAll('[data-submenu-toggler]').forEach(el => {
        el.ariaExpanded = 'false';
        el.ariaLabel = el.dataset.ariaLabelOpen;

        el.addEventListener('click', e => {
            e.preventDefault();
            e.currentTarget.parentNode.classList.toggle('submenu-show');

            const isClosed = el.ariaExpanded === 'false';

            el.ariaLabel = isClosed ? el.dataset.ariaLabelClose : el.dataset.ariaLabelOpen;
            el.ariaExpanded = isClosed ? 'true' : 'false';
        });
    });

    const locationSwitcherTrigger = menu.querySelector('.header__menu-navigation-cockpit-link');

    // Power up the location switcher trigger in header menu navigation
    if (locationSwitcherTrigger) {
        locationSwitcherTrigger.addEventListener('click', e => {
            e.preventDefault();
            overlay.hide();
            openLocationSwitcher();
        });
    }
}
