import createOverlay from '../../../_helpers/overlay';

const bodyCssClass = 'profile-switch-modal-active';

export default () => {
    if (document.body.classList.contains('page-layout-cockpit')) {
        return;
    }

    const modal = document.querySelector('[data-app-profile-switch-modal]');

    if (!modal) {
        return;
    }

    const links = [...document.querySelectorAll('a[data-app-profile-switch-link]')];
    const modalClose = modal.querySelector('[data-app-profile-switch-modal-close]');
    const modalLink = modal.querySelector('[data-app-profile-switch-modal-link]');
    const overlay = createOverlay(() => document.body.classList.remove(bodyCssClass));

    modalClose.addEventListener('click', e => {
        e.preventDefault();
        overlay.hide();
    });

    links.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
            overlay.show();
            document.body.classList.add(bodyCssClass);
            modalLink.href = e.currentTarget.href;
        });
    });

    // Close the overlay on "escape" key press
    document.addEventListener('keyup', e => {
        if (e.code === 'Escape' && document.body.classList.contains(bodyCssClass)) {
            overlay.hide();
        }
    });
}
