import {
    getLocation,
    initGeolocation,
    initLocationSearch,
    initPageSearch,
    isGeolocationSupported,
    subscribe
} from '../../../_helpers/location';
import createOverlay from '../../../_helpers/overlay';

const bodyCssClass = 'location-switcher-active';
const triggerActiveCssClass = 'header__location-switcher-trigger--active';

const overlay = createOverlay(() => document.body.classList.remove(bodyCssClass));

let switcherInput;

export function openLocationSwitcher() {
    overlay.show();
    document.body.classList.add(bodyCssClass);

    // Autofocus the switcher input element
    if (switcherInput) {
        switcherInput.focus();
    }
}

export function initLocationSwitcher() {
    const switcher = document.querySelector('[data-location-switcher]');

    if (!switcher) {
        return;
    }

    const trigger = document.querySelector('[data-location-switcher-trigger]');
    const results = switcher.querySelector('[data-location-switcher-results]');

    if (!trigger || !results) {
        return;
    }

    const geolocationElement = switcher.querySelector('[data-location-switcher-geolocation]');
    const linkToMapElement = switcher.querySelector('[data-location-switcher-link-to-map]');
    const locationElement = trigger.querySelector('[data-location-switcher-trigger-location]');
    const currentLocation = getLocation();

    switcherInput = switcher.querySelector('[data-location-switcher-input]');

    function onSelectionCallback() {
        redirectToMap();
        overlay.hide();
    }

    function redirectToMap() {
        const location = getLocation();

        if (location !== null) {
            window.location.href = `${switcher.dataset.locationSwitcher}#/map/${encodeURIComponent(location.y)},${encodeURIComponent(location.x)}/${encodeURIComponent(location.name)}`;
        }
    }

    // Remove the link to map element in cockpit view
    if (linkToMapElement) {
        if (document.body.classList.contains('page-layout-cockpit')) {
            linkToMapElement.parentNode.removeChild(linkToMapElement);
        } else {
            // Otherwise power up the link to map element
            linkToMapElement.addEventListener('click', e => {
                e.preventDefault();
                redirectToMap();
            });

            linkToMapElement.style.display = currentLocation ? 'block' : 'none';
        }
    }

    // Set the current location as trigger text
    if (currentLocation) {
        trigger.classList.add(triggerActiveCssClass);
        locationElement.innerText = currentLocation.name;
        switcherInput.value = currentLocation.name;
    }

    // Power up the geolocation
    if (isGeolocationSupported()) {
        initGeolocation(geolocationElement, onSelectionCallback);
    } else {
        geolocationElement.parentNode.removeChild(geolocationElement);
    }

    // Subscribe to the location change
    subscribe(location => {
        if (location !== null) {
            trigger.classList.add(triggerActiveCssClass);
            locationElement.innerText = location.name;
            switcherInput.value = location.name;

            if (linkToMapElement) {
                linkToMapElement.style.display = 'block';
            }
        } else {
            trigger.classList.remove(triggerActiveCssClass);
            locationElement.innerText = '';
            switcherInput.value = '';

            if (linkToMapElement) {
                linkToMapElement.style.display = 'none';
            }
        }
    });

    // Append switcher at the end of <body>
    document.body.appendChild(switcher);

    // Power up the trigger
    trigger.addEventListener('click', e => {
        e.preventDefault();
        openLocationSwitcher();
    });

    // Power up the switcher input
    initLocationSearch(switcherInput, results, onSelectionCallback);

    // Power up the page search
    initPageSearch((keywords) => {
        openLocationSwitcher();
        switcherInput.value = keywords;
        switcherInput.dispatchEvent(new window.CustomEvent('input'));
    });

    // Power up content trigger links
    [...document.querySelectorAll('[data-location-switcher-link]')].forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
            openLocationSwitcher();
        });
    });

    // Close the overlay on "escape" key press
    document.addEventListener('keyup', e => {
        if (e.code === 'Escape' && document.body.classList.contains(bodyCssClass)) {
            overlay.hide();
        }
    });
}
