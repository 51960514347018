const overlayCssClass = 'page-overlay';
const bodyCssClass = 'page-overlay-active';

class Overlay {
    constructor(closeCallback = () => {}) {
        this.el = document.createElement('div');
        this.el.className = overlayCssClass;
        this.el.addEventListener('click', this.hide.bind(this));

        this.active = false;
        this.closeCallback = closeCallback;
    }

    show() {
        document.body.insertBefore(this.el, document.body.childNodes[0]);
        document.body.classList.add(bodyCssClass);

        this.active = true;
    }

    hide() {
        if (this.el.parentElement) {
            this.el.parentElement.removeChild(this.el);
        }

        document.body.classList.remove(bodyCssClass);

        this.active = false;
        this.closeCallback();
    }

    toggle() {
        this.active ? this.hide() : this.show();
    }
}

export default closeCallback => new Overlay(closeCallback);
