const LOCAL_STORAGE_KEY = 'canton-page';

export const updateCantonLinks = () => {
    const cantonPage = window.localStorage ? window.localStorage.getItem(LOCAL_STORAGE_KEY) : null;

    if (!cantonPage) {
        return;
    }

    // Replace all links pointing to canton select page with "active" canton page
    [...document.querySelectorAll(`a[href*="${window.AppCantonOverviewPage}"]`)].forEach(el => {
        let parent = el.parentNode;

        do {
            // Do not replace links that are inside particular elements
            if (parent.classList.contains('reset-canton-page-link')) {
                return;
            }

            parent = parent.parentNode;
        } while (parent !== document.body && parent !== null);

        let url = cantonPage;

        if (window.AppProfile) {
            url = `${url}?profile=${window.AppProfile}`;
        }

        el.href = el.href.replace(window.AppCantonOverviewPage, url);
    });
}

export const setCanton = canton => {
    if (window.localStorage) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, canton);
    }

    updateCantonLinks();
};

export const resetCanton = () => {
    if (window.localStorage) {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
};

export const initCantonPage = () => {
    // Remove the canton page from local storage
    if (window.AppCantonReset) {
        resetCanton();
    }

    // Return if there is no canton overview page to be replaced
    if (!window.AppCantonOverviewPage) {
        return;
    }

    // Store the canton page in local storage
    if (window.AppCanton) {
        setCanton(window.AppCanton);
    }
};
