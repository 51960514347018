import * as Sentry from '@sentry/browser';

export function initSentry() {
    if (window.AppDev) {
        return;
    }

    Sentry.init({
        dsn: 'https://48db4b1f1d11495694269e48bdd663cf@o27120.ingest.sentry.io/5375479',
        allowUrls: [
            `${window.location.hostname}/layout`
        ],
        ignoreErrors: [
            'Attempted to assign to readonly property',
            'can\'t redefine non-configurable property "userAgent"',
            'coordinates must be finite numbers',
            'Pending operation cancelled by requestFullscreen() call.',
            'ResizeObserver loop completed with undelivered notifications.',
            'Error: Maximum execution time of 30 seconds exceeded',
            'timeout of 0ms exceeded',
            'Illegal invocation',
            'Network Error',
            'No error message',
            'No identifiers allowed directly after numeric literal',
            'Non-Error promise rejection captured with value',
            'Request aborted',
            /Request failed with status code \d+/,
            'SecurityError: The operation is insecure.',
            'TableFilter could not load: app/tablefilter/style/tablefilter.css',
            /Loading(\sCSS)? chunk \d+ failed./,
            'Unsupported coordinates format',
            '{}',
            /^undefined is not an object/,
            /^Cannot read properties of/,
            /^Assertion failed/,
            /is not defined$/,
            '\'e.getAttribute\' is not a function',
            /^Can't find variable/,
            'URI malformed',
            'InvalidStateError: The object is in an invalid state.',
            'Cannot fit empty extent provided as `geometry`',
            /is not a function$/,
            /^Unexpected token/,
            /^window\.refreshUI is not a function/,
        ],
    });
}

export function captureException(error) {
    if (window.AppDev) {
        return;
    }

    Sentry.captureException(error);
}
