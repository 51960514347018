import 'respimage/respimage.min.js';
import 'what-input';

import { loadForElements } from '../../_helpers/module-loader';
import { initLocationSwitcher } from './components/location-switcher';
import { initSentry } from '../../_helpers/sentry';
import initAnchors from './components/anchors';
import { initCantonPage } from './components/canton-page';
import initMenu from './components/menu';
import initProfileSwitch from './components/profile-switch';

initSentry();

export function initContentFeatures() {
    initCantonPage();

    loadForElements([...document.querySelectorAll('.ce_accordion, .handorgel')], () => import('./components/accordion'));
    loadForElements([...document.querySelectorAll('[data-animation]')], () => import('./components/animations'));
    loadForElements([...document.querySelectorAll('[data-homepage-profile-popup]')], () => import('./components/homepage-profile-popup'));
    loadForElements([...document.querySelectorAll('[data-hail-protection-inquiry-form]')], () => import('./components/hail-protection-inquiry-form'));
    loadForElements([...document.querySelectorAll('[data-hail-protection-register]')], () => import('./components/hail-protection-register'));
    loadForElements([...document.querySelectorAll('[data-tags-filters]')], () => import('./components/tags-filters'));
    loadForElements([...document.querySelectorAll('a[data-lightbox]')], () => import('./components/lightbox'), 'initLinks');
    loadForElements([...document.querySelectorAll('a[data-lightbox-table]')], () => import('./components/lightbox'), 'initTables');
    loadForElements([...document.querySelectorAll('[data-slider]')], () => import('./components/slider'));
    loadForElements([...document.querySelectorAll('[data-table]')], () => import('./components/tables'));
    loadForElements([...document.querySelectorAll('[data-toolbar-action]')], () => import('./components/toolbar-actions'), 'init');
    loadForElements([...document.querySelectorAll('[data-tippy-content]')], () => import('./components/tooltips'));
    loadForElements([...document.querySelectorAll('[data-video]')], () => import('./components/videos'));
}

document.addEventListener('DOMContentLoaded', () => {
    initAnchors(); // must come before accordion
    initContentFeatures();
    initLocationSwitcher();
    initProfileSwitch();
    initMenu();

    // Add IE11 class to <body> if needed
    if (!!window.MSInputMethodContext && !!document.documentMode) {
        document.body.classList.add('ie11');
    }
});

// Force load all images before printing
window.addEventListener('beforeprint', () => {
    [...document.querySelectorAll('img[loading="lazy"]')].forEach(el => el.loading = 'eager');
});
