import autoComplete from '@tarekraafat/autocomplete.js/dist/js/autoComplete';
import axios from 'axios';
import proj4, {projection} from './proj4';
import handleError from './error-handler';

const coordinatesInputFormats = [
    {
        name: 'EPSG:2056',
        bounds: {
            y: { min: 2485071.58, max: 2828515.82 },
            x: { min: 1075346.31, max: 1299941.79 },
        },
    },
    {
        name: 'EPSG:21781',
        bounds: {
            y: { min: 485071.54, max: 828515.78 },
            x: { min: 75346.36, max: 299941.84 },
        },
    },
    {
        name: 'WGS84',
        bounds: {
            // Reversed order according to epsg.io data
            y: { min: 45.82, max: 47.81 },
            x: { min: 5.96, max: 10.49 },
        },
    },
];

const searchMinimumCharacters = 3;

export function searchLocations(query) {
    return new Promise((resolve, reject) => {
        query = query.replace(',', '');

        if (!query || query.length < searchMinimumCharacters) {
            return resolve({ locations: [], query });
        }

        axios.get('https://api3.geo.admin.ch/rest/services/api/SearchServer', {
            params: {
                'sr': 2056,
                'searchText': query,
                'type': 'locations',
                'geometryFormat': 'geojson',
            }
        }).then(response => {
            resolve({
                locations: response.data.features.map(location => ({
                    name: location.properties.label.replace(/<[^>]*>?/gm, '').replace(/\s?\([^\)]+\)/g, ''), // strip HTML tags
                    x: location.properties.x,
                    y: location.properties.y,
                })),
                query,
            });
        }).catch((error) => {
            reject(error);
            handleError(error);
        });
    });
}

export function isGeolocationSupported() {
    return window.isSecureContext && navigator.geolocation !== undefined;
}

export function initGeolocation(buttonEl, onSelectionCallback = () => {}) {
    buttonEl.addEventListener('click', e => {
        e.preventDefault();
        navigator.geolocation.getCurrentPosition((position) => {
            onSelectionCallback(proj4('WGS84', projection, [position.coords.longitude, position.coords.latitude]));
        }, () => {});
    });
}

export function initLocationSearch(inputEl, resultsEl, onSelectionCallback = () => {}, cssPrefix = 'location-switcher', labels = window.AppLabels) {
    const hashId = Math.random().toString(36).substring(2, 5);

    const errorEl = document.createElement('p');
    errorEl.id = `sng-location-error-${hashId}`;
    errorEl.className = `${cssPrefix}__error`;
    errorEl.style.display = 'none';
    errorEl.innerText = labels.incorrectLocation;
    inputEl.dataset.sngLocationErrorElement = errorEl.id;
    inputEl.parentNode.parentNode.insertBefore(errorEl, inputEl.parentNode);

    new autoComplete({
        selector: () => inputEl,
        debounce: 300,
        searchEngine: (query, record) => record,
        onSelection: result => {
            inputEl.value = result.selection.value.name;
            onSelectionCallback(result.selection.value);
        },
        resultsList: {
            render: true,
            container: source => {
                source.id = `location-switcher-results-${hashId}`;
                source.className = `${cssPrefix}__results-list`;
                source.setAttribute('role', 'listbox');
                source.setAttribute('aria-label', labels.locationResults);
                source.setAttribute('aria-live', 'assertive');
                source.setAttribute('aria-expanded', ((source.children.length > 0) ? 'true' : 'false'));

                inputEl.setAttribute('aria-controls', source.id);
            },
            destination: resultsEl,
            position: 'beforeend',
            element: 'ul'
        },
        resultItem: {
            element: 'button',
            content: (data, source) => {
                resultsEl.ariaExpanded = 'true';

                source.type = 'button';
                source.className = `${cssPrefix}__result`;
                source.innerHTML = data.value.name;
            },
        },
        noResults() {
            resultsEl.ariaExpanded = 'true';

            // Do not display empty message if coordinates were entered
            if (validateInput(inputEl) !== null) {
                return;
            }

            const result = document.createElement('li');
            result.setAttribute('class', `${cssPrefix}__results-empty`);
            result.innerHTML = labels.emptyLocations;
            this.resultsList.view.appendChild(result);
        },
        query: {
            manipulate: (query) => {
                if (!query) {
                    resultsEl.ariaExpanded = 'false';
                }

                return query.replace(',', '');
            }
        },
        data: {
            src: async () => {
                let items = [];

                await searchLocations(inputEl.value).then(result => { items = result.locations });

                return items;
            },
            key: ['name'],
            cache: false,
        },
    });

    // Accept the coordinates
    inputEl.addEventListener('keyup', e => {
        errorEl.style.display = 'none';

        if (e.key === 'Enter') {
            const coordinates = validateInput(inputEl);

            if (coordinates !== null) {
                onSelectionCallback({
                    name: `${coordinates[0].toFixed(2)}, ${coordinates[1].toFixed(2)}`,
                    y: coordinates[0],
                    x: coordinates[1],
                });
            }
        }

        if (e.key === 'Escape') {
            resultsEl.childNodes[0].innerHTML = '';
        }
    });
}

export function validateInput(inputEl) {
    if (!inputEl.value) {
        return null;
    }

    const coordinatesMatch = inputEl.value.match(/\d/g);

    // Input with at least 12 digits is likely the coordinates
    if (!coordinatesMatch || coordinatesMatch.length < 12) {
        return null;
    }

    // Matches:
    // - 46.908516756385026,7.305908203125001
    // - 2'615'185.0, 1'170'252.0
    // - 615185/170252
    // - 2589884.32,1213556.61
    const matches = /^([\d.']+)([\s,/]+)([\d.']+)$/.exec(inputEl.value);

    if (matches === null) {
        document.getElementById(inputEl.dataset.sngLocationErrorElement).style.display = 'block';

        return null;
    }

    return parseCoordinates(matches[1], matches[3]);
}

export function parseCoordinates(latitude, longitude) {
    // Set the coordinates by replacing everything that is not a digit or a point
    let coordinates = [
        parseFloat(latitude.replace(/[^\d.]/g, '')),
        parseFloat(longitude.replace(/[^\d.]/g, '')),
    ];

    // Flip the coordinates if they are switched
    if (coordinates[1] > coordinates[0]) {
        // noinspection JSSuspiciousNameCombination
        coordinates = coordinates.reverse();
    }

    const format = coordinatesInputFormats.find(v => (coordinates[0] >= v.bounds.y.min && coordinates[0] <= v.bounds.y.max) && (coordinates[1] >= v.bounds.x.min && coordinates[1] <= v.bounds.x.max));

    if (format === undefined) {
        return null;
    }

    // Convert the format
    if (format.name !== projection) {
        // Reverse the coordinates for WGS84
        if (format.name === 'WGS84') {
            coordinates = coordinates.reverse();
        }

        coordinates = proj4(format.name, projection, coordinates);
    }

    return coordinates;
}
